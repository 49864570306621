import { LanguageProvider } from "./pages/components/languages/Language";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import ProtectedRoute from './auth/ProtectedRoute';
import Courses from './pages/Courses';
import Topics from './pages/Topics';
import Content from './pages/Content';
import Test from "./pages/test/Test";
import Login from './pages/login/Login';
import Payments from "./pages/wallet/Payments";

function App() {
  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Courses />} />
          <Route path="/topics/:id" element={<Topics />} />
          <Route path="/content/:topicid" element={<Content />} />
          <Route path="/test/:topicid" element={<Test />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Courses />} />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;