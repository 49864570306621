import React from "react";

export default function Mark ({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
            <g>
                <path transform="rotate(0,16,16) translate(4.42336399344988,0) scale(0.999970317767963,0.999970317767963)" fill={color} d="M0,3.1199493L15.981001,3.1199493 15.981001,3.219925 13.983071,5.11696 1.9979302,5.11696 1.9979302,26.341948 7.8280245,19.613917 13.983071,26.671965 13.983071,13.68093 15.981001,11.603964 15.981001,32.000949 7.8320529,22.658963 0,31.696933z M21.848958,0L23.15396,1.2650002 11.529946,13.349001 5.725939,8.7390006 6.8509399,7.3110006 11.366946,10.898001z" />
            </g>
        </svg>
    );
}