import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import auth from './auth/auth';

let API_URL = process.env.REACT_APP_API_URL;

const OTP = (props) => {
    const [OTPCalls, setOTPCalls] = useState(false);
    const [OTPTimeout, setOTPTimeout] = useState(0);
    // const [OTPServer, setOTPServer] = useState('');
    const [OTPClient, setOTPClient] = useState('');
    const [OTPStatus, setOTPStatus] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        requestOTP();
    }, []);

    function requestOTP() {
        setOTPCalls(true);
        setOTPTimeout(30);
        setOTPStatus('SEND_OTP_BEGIN');
        fetch(`${API_URL}/requestotp?phonenumber=${props.country.iso}.${props.phoneNumber}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        }).then(response => response.json())
            .then(json => { 
                setOTPStatus('SEND_OTP_SUCCESS');
                // setOTPServer(json.otp);
                let timer = setInterval(() => {
                    setOTPTimeout(OTPTimeout - 1);
                    if (OTPTimeout <= 1) {
                        clearInterval(timer);
                    }
                }, 1000);
            })
            .catch(error => setOTPStatus('SEND_OTP_FAIL'))
    }
    function changeOTP (e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setOTPClient(e.target.value);
        }
    }
    function verifyOTP () {
        if (OTPClient.length === 6) {
            setOTPStatus('VERIFY_OTP_BEGIN');
            let url = `${API_URL}/verifyotp?phonenumber=${props.country.iso}.${props.phoneNumber}&otp=${OTPClient}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'text/plain',
                }
            })
            .then(response => response.json())
            .then(json => {
                if (!json.Error) {
                    setOTPStatus('VERIFY_OTP_SUCCESS');
                    auth.login(props.country, props.phoneNumber, json.token);
                    navigate(props.fromURL);
                } else {
                    setOTPStatus('VERIFY_OTP_FAIL');
                }
            })
            .catch(error => setOTPStatus('VERIFY_OTP_FAIL'));
        }
    }
    return (
        <div style={{width:'280px'}}>
            <div style={{display:'flex',alignItems:'center',padding:'4px 8px',borderWidth:'2px',borderStyle:'dashed',borderColor:'steelblue',backgroundColor:'aliceblue',color:'darkslategray',cursor:'pointer'}} onClick={props.resetPhoneNumber}>
                <div style={{paddingRight:'8px'}}>{props.country.iso} - {props.phoneNumber}</div>
                <svg fill="darkslategray" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="16px" height="16px"><path d="M38.657 18.536l2.44-2.44c2.534-2.534 2.534-6.658 0-9.193-1.227-1.226-2.858-1.9-4.597-1.9s-3.371.675-4.597 1.901l-2.439 2.439L38.657 18.536zM27.343 11.464L9.274 29.533c-.385.385-.678.86-.848 1.375L5.076 41.029c-.179.538-.038 1.131.363 1.532C5.726 42.847 6.108 43 6.5 43c.158 0 .317-.025.472-.076l10.118-3.351c.517-.17.993-.463 1.378-.849l18.068-18.068L27.343 11.464z"/></svg>
            </div>
            {(OTPTimeout === 0) ? (
                <button style={{backgroundColor: "rosybrown", color: "whitesmoke", marginTop:'4px',padding:'8px',border:'none',cursor:'pointer'}} onClick={requestOTP}>
                    Request OTP { (OTPCalls === true) ? "again" : "" }
                </button>) : (
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',fontSize:'smaller',backgroundColor:'#e0ebf0',color:'gray',marginTop:'4px',padding:'8px',border:'none'}}>
                    <div>OTP sent to you</div>
                    <div>wait for <strong>{OTPTimeout}</strong> seconds</div>
                </div>)
            }
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'2px solid slategray',marginTop:'32px',paddingBottom:'4px'}}>
                <input type="text" style={{border:'none',backgroundColor:'transparent',outline:'none'}} placeholder='OTP' value={OTPClient} onChange={e => changeOTP(e)} />
                { (OTPStatus === 'VERIFY_OTP_FAIL') ? <div style={{color:'deeppink',fontSize:'small',alignSelf:'flex-end'}}><strong>wrong OTP</strong></div> : null }
                { (OTPStatus === 'SEND_OTP_SUCCESS') ? <div>OTP sent.</div> : null }
                {/* { (OTPStatus === 'SEND_OTP_SUCCESS') ? <div>Your OTP is: <strong style={{color:'green'}}>{OTPServer}</strong></div> : null } */}
            </div>
            {(OTPClient.length !== 6) ?
                <button style={{backgroundColor: "#e0ebf0",color: "gray", marginTop:'4px',padding:'8px',border:'none'}}>
                    Verify OTP
                </button> :
                <button style={{backgroundColor: "rosybrown",color: "whitesmoke", marginTop:'4px',padding:'8px',border:'none',cursor:'pointer'}} onClick={verifyOTP}>
                    Verify OTP
                </button>
            }
        </div>
    )
}

export default OTP;