import { useState, useEffect, useRef } from "react";
import { marked } from "marked";
import auth from "../login/auth/auth";
import { findCourseName } from "../components/utils"
import Mark from "../components/svg/mark";
import Block from "../components/svg/block";
let API_URL = process.env.REACT_APP_API_URL;
marked.setOptions({
    breaks: true
});
const renderer = new marked.Renderer();

const Question = ({id}) => {
    const [qn, setQn] = useState({});
    const [content, setContent] = useState('');

    useEffect(() => {
        let url = `${API_URL}/question?id=${id}&token=${auth.token}`;
        fetch(url, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                setQn(data);
            })
            .catch(error => console.log(error));
    }, [id])

    useEffect(() => {
        if (qn.url) {
            return;
        }
        const load = async () => {
            const tokens = id.split(".");
            const courseName = findCourseName(tokens);
            const mdl = await import(`../../courses/${courseName}.json`);
            let mdlTopic = mdl.default;
            if (tokens.length > 1) {
                let ts = [...tokens];
                ts.shift();
                ts.forEach(t => {
                    mdlTopic = {...mdlTopic[t]};
                });
                setQn({...qn, ...mdlTopic});
                fetch(mdlTopic.url, { method: 'GET' })
                    .then(response => response.text())
                    .then(result => {
                        setContent(result);
                    })
                    .catch(error => error);
            }
        }
        load();
    }, [qn])

    async function markContent (is_marked, is_blocked) {
        let url = `${API_URL}/tag_content?id=${qn}&content_type=question&is_marked=${is_marked}&is_blocked=${is_blocked}&token=${auth.token}`;
        try {
            const response = await fetch(url, { method: 'GET', headers: { 'Accept': 'text/plain' } });
            const jsn = await response.json();
            setQn({...qn, ...jsn });
        } catch (err) {}
    }

	return <>
        <header style={{backgroundColor:'rgb(0, 105, 255)',color:'ghostwhite'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <div style={{display:'flex',flexDirection:'column',alignSelf:'center',marginLeft:'6px'}}>
                    <h4>{qn.title}</h4>
                    <div style={{display:'flex',alignSelf:'center'}}>
                        <span>Visited <strong>{qn.visits}</strong> times</span>
                        <span style={{margin: '0 12px'}}>Answered <strong>{qn.answered_times}</strong> times</span>
                        <span>Cleared <strong>{JSON.stringify(qn.cleared)}</strong></span>
                    </div>
                </div>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
                {(parseInt(qn.is_marked) === 0) ?
                    <div className={'svg'} style={{cursor:'pointer',padding:'6px'}} onClick={() => markContent(1, 0)}>
                        <Mark color="darkgray" />
                    </div> :
                    <div className={'svg'} style={{cursor:'pointer',padding:'6px'}} onClick={() => markContent(0, 0)}>
                        <Mark color="ghostwhite" />
                    </div>
                }
                {(parseInt(qn.is_blocked) === 0) ?
                    <div className={'svg'} style={{cursor:'pointer',padding:'6px 12px 6px 6px'}} onClick={() => markContent(0, 1)}>
                        <Block color="darkgray" />
                    </div> :
                    <div className={'svg'} style={{cursor:'pointer',padding:'6px 12px 6px 6px'}} onClick={() => markContent(0, 0)}>
                        <Block color="ghostwhite" />
                    </div>
                }
            </div>
        </header>
        <article>
            <main>
                {content.trim().length > 0 && <div style={{padding:'12px 12px 12px 24px',maxWidth:'1200px'}} dangerouslySetInnerHTML={{__html: marked(content, { renderer: renderer })}} />}
            </main>
        </article>
        <Answer qn={qn} id={id} />
    </>
}

export default Question;

const Answer = ({qn, id}) => {
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [answerSC, setAnswerSC] = useState([0, 0, 0, 0, 0]);
    const [answerMC, setAnswerMC] = useState([0, 0, 0, 0, 0]);
    const [answerMMFB, setAnswerMMFB] = useState([1,2,3,4,5]);
    const [isAnswered, setIsAnswered] = useState(false);

    useEffect(() => {
        if (isAnswered) {
            setIsAnswered(false);
        }
    }, [qn])

    function changeSCArray(index) {
        let arr = [...answerSC];
        for(var i = 1; i< 6; i++) {
            arr[i-1] = 0;
            if (arr[index-1] === 0) {
                arr[index-1] = 1; 
            }
        }
        setAnswerSC([...arr]);
    }

    function changeMCArray(index) {
        let arr = [...answerMC];
        if (arr[index-1] === 1) {
            arr[index-1] = 0; 
        } else {
            arr[index-1] = 1;
        }
        setAnswerMC([...arr]);
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
    };
     
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
     
    const drop = (e) => {
        const copyListItems = [...answerMMFB];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setAnswerMMFB(copyListItems);
    };

    async function submitAnswer () {
        let answered_times = qn.answered_times + 1;
        let cleared = Number.parseFloat(qn.cleared.toFixed(2));
        let answeredCorrectly = false;
        if (qn.subtype === "SC") {
            let i = qn.answer[0];
            if (answerSC[i-1] === 1) {
                answeredCorrectly = true;
            }
        }
        if (qn.subtype === "MC") {
            qn.answer.sort();
            let asnwerTemp = [];
            for(var i = 0; i < 5; i++) {
                if (answerMC[i] === 1) {
                    asnwerTemp.push(i+1);
                }
            }
            answeredCorrectly = (asnwerTemp.join("") === qn.answer.join(""));
        }
        if ((qn.subtype === "MM") || (qn.subtype === "FB")) {
            if (qn.answer.sort().join("") === answerMMFB.sort().join("")) {
                answeredCorrectly = true;
            }
        }
        if (answeredCorrectly === true) {
            if (cleared === 0) {
                cleared = 0.25;
            } else if (cleared === 0.25) {
                cleared = 0.50;
            } else if (cleared === 0.50) {
                cleared = 1;
            } else {
                cleared = 0;
            }
        } else {
            cleared = 0;
        }
        let url = `${API_URL}/submit_answer?id=${id}&answered_times=${answered_times}&cleared=${cleared}&token=${auth.token}`;
        try {
            const response = await fetch(url, { method: 'GET', headers: { 'Accept': 'text/plain' } });
            const jsn = await response.json();
            setIsAnswered(true);
        } catch (err) {}
    }

    return <header style={{display:'flex',backgroundColor:'#f5f5f5'}}>
        <div style={{display:'flex',alignItems:'center'}}>
            <h3 style={{margin:'0 12px'}}>Answer</h3>
            {(qn.subtype === "SC") && [1,2,3,4,5].map(a => {
                return <h3 style={{backgroundColor: answerSC[a-1] === 1 ? "skyblue" : "silver",margin:'6px',padding:'6px 12px',borderRadius:'6px',cursor:'grab'}} key={a} onClick={() => changeSCArray(a)}>{a}</h3>
            })}
            {(qn.subtype === "MC") && [1,2,3,4,5].map(a => {
                return <h3 style={{backgroundColor: answerMC[a-1] === 1 ? "skyblue" : "silver",margin:'6px',padding:'6px 12px',borderRadius:'6px',cursor:'pointer'}} key={a} onClick={() => changeMCArray(a)}>{a}</h3>
            })}
            {((qn.subtype === "MM") || (qn.subtype === "FB")) && answerMMFB.map((a, index) => {
                return <h3 style={{backgroundColor:'lightblue',margin:'6px',padding:'6px 12px',borderRadius:'6px',cursor:'grab'}}
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    key={index}
                    draggable>
                    {a}
                </h3>
            })}
        </div>
        {isAnswered
            ? <h4 style={{display:'flex',alignSelf:'center',margin:'6px 12px',color:'freen'}}>Answer Submitted</h4>
            : <div onClick={() => submitAnswer()} style={{display:'flex',alignSelf:'center',cursor:'pointer',padding:'6px 9px 6px 6px',backgroundColor:'green',color:'whitesmoke',borderRadius:'4px',margin:'6px 12px'}}><h4>Submit Answer</h4></div>
        }
    </header>
}