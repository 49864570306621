import coursesFile from '../../courses/courses.json';

const findCourseName = (tokens) => {
    for (const mainCategory in coursesFile) {
        const mc = coursesFile[mainCategory];
        for (const subCategory in mc) {
            const sc = mc[subCategory];
            if (typeof (sc) === 'object') {
                for (const course in sc) {
                    if (typeof (sc[course]) === 'object') {
                        if (sc[course].id === tokens[0]) {
                            return course;    
                        }
                    }
                }
            }
        }
    }
    return undefined;
}

const topicSubTypeAbbreviations = {
    "SC": "Single Choice",
    "MC": "Multiple Choice",
    "MM": "Matrix Match",
    "FB": "Fill in the Blanks"
};

export { findCourseName, topicSubTypeAbbreviations };