import { useState, Suspense, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player';
import { marked } from "marked";
import auth from "./login/auth/auth";
import { findCourseName } from "./components/utils";
import Mark from "./components/svg/mark";
import Block from "./components/svg/block";

let API_URL = process.env.REACT_APP_API_URL;
marked.setOptions({
    breaks: true
});
const renderer = new marked.Renderer();

const Content = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [topic, setTopic] = useState({});
    const [selectedTab, setSelectedTab] = useState("Content");
    const [content, setContent] = useState("");
    const [explanation, setExplanation] = useState("");
    const [note, setNote] = useState("");
    const [editedNote, setEditedNote] = useState("");
    const [isNoteEdit, setIsNoteEdit] = useState(false);

    const topicid = location.pathname.replace("/content/", "");
    const backURL = (() => {
        const tokens = topicid.split(".");
        if (tokens.length === 1) {
            return '/';
        } else if (tokens.length > 1) {
            let t = [...tokens];
            t.pop();
            t = t.join('.')
            return '/topics/' + t;
        }
    })();

    useEffect(() => {
        const tokens = topicid.split(".");
        if (auth.token === '') {
            navigate(backURL);
        }
        const courseName = findCourseName(tokens);
        let mdlTopic = undefined;
        const load = async () => {
            const mdl = await import(`../courses/${courseName}.json`);
            mdlTopic = mdl.default;
            tokens.shift();
            tokens.forEach(t => {
                mdlTopic = mdlTopic[t];
            });
            let t = (mdlTopic.type === "Question") ? "question_visits_amountbalance" : "topic_visits_amountbalance";
            fetch(`${API_URL}/${t}?id=${topicid}&token=${auth.token}`, { method: 'GET' })
                .then(response => response.json())
                .then(result => {
                    setTopic({...mdlTopic, ...result});
                })
                .catch(error => error);
            if (mdlTopic.type !== "Video") {
                fetch(mdlTopic.url, { method: 'GET' })
                    .then(response => response.text())
                    .then(result => {
                        setContent(result);
                    })
                    .catch(error => error);
            }
            if (mdlTopic.dtype === "Text") {
                fetch(mdlTopic.durl, { method: 'GET' })
                    .then(response => response.text())
                    .then(result => {
                        setExplanation(result);
                    })
                    .catch(error => error);
            }
            fetch(`${API_URL}/load_note?id=${topicid}&token=${auth.token}`, { method: 'GET' })
                .then(response => response.text())
                .then(result => {
                    setNote(result);
                    setEditedNote(result);
                })
                .catch(error => error);
        }
        load();
    }, [])

    async function tagContent (content_type, is_marked, is_blocked) {
        let url = `${API_URL}/tag_content?id=${topicid}&content_type=${content_type}&is_marked=${is_marked}&is_blocked=${is_blocked}&token=${auth.token}`;
        try {
            const response = await fetch(url, { method: 'GET', headers: { 'Accept': 'text/plain' } });
            const jsn = await response.json();
            setTopic({...topic, ...jsn });
        } catch (err) {}
    }

    async function saveNote () {
        let url = `${API_URL}/update_note`;
        let data = {
            id: topicid,
            token: auth.token,
            content: note
        };
        const response = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data)
        });
        await response.json();
    }
    
    function resetNote () {
        let tempNote = "";
        if (content.trim().length > 0 && topic.type !== "Video") {
            tempNote = content;
        }
        if (explanation.trim().length > 0 && topic.dtype !== "Video") {
            tempNote = explanation;
        }
        setNote(tempNote);
        setEditedNote(tempNote);
    }

    return (
        <div className="content">
            <header style={{backgroundColor:'#202c38',color:'#ac8e6a'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <Link style={{display:'flex',justifyContent:'center',width:'48px',height:'48px',alignItems:'center',alignSelf:'center',cursor:'pointer'}} to={backURL}>
                        <span style={{display:'flex',justifyContent:'center',fontFamily:'Arial, sans-serif',border:'2px solid #ac8e6a',borderRadius:'50% 50% 50% 50%',width:'32px',height:'32px',color:'#ac8e6a',fontSize:'24px',fontWeight:'bolder'}}>↑</span>
                    </Link>
                    <div style={{display:'flex',flexDirection:'column',marginLeft:'6px'}}>
                        <h4>{topic.title}</h4>
                        <div>
                            <span>Visited <strong>{topic.visits}</strong> times</span>
                            {topic.type === "Question" && <>
                                <span style={{margin: '0 12px'}}>Answered <strong>{topic.answered_times}</strong> times</span>
                                <span><strong>{topic.cleared === 1 ? "Cleared " : "Not Cleared"}</strong></span>
                            </>}
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                    {(parseInt(topic.is_marked) === 0) ?
                        <div className={'svg'} style={{cursor:'pointer',padding:'6px'}} onClick={() => tagContent((topic.type === "Question") ? "question" : "topic", 1, 0)}>
                            <Mark color="darkgray" />
                        </div> :
                        <div className={'svg'} style={{cursor:'pointer',padding:'6px'}} onClick={() => tagContent((topic.type === "Question") ? "question" : "topic", 0, 0)}>
                            <Mark color="steelblue" />
                        </div>
                    }
                    {(parseInt(topic.is_blocked) === 0) ?
                        <div className={'svg'} style={{cursor:'pointer',padding:'6px 12px 6px 6px'}} onClick={() => tagContent((topic.type === "Question") ? "question" : "topic", 0, 1)}>
                            <Block color="darkgray" />
                        </div> :
                        <div className={'svg'} style={{cursor:'pointer',padding:'6px 12px 6px 6px'}} onClick={() => tagContent((topic.type === "Question") ? "question" : "topic", 0, 0)}>
                            <Block color="steelblue" />
                        </div>
                    }
                </div>
            </header>
            <header>
                <div style={{display:'flex'}}>
                    <h4 style={{padding:'12px 20px',borderBottom:`2px solid ${(selectedTab === "Content") ? 'black' : 'transparent'}`,color:`${(selectedTab === "Content") ? 'black' : 'steelblue'}`,cursor:`${(selectedTab === "Content") ? 'default' : 'pointer'}`}} onClick={() => (selectedTab !== "Content") && setSelectedTab("Content")}>Content</h4>
                    { topic.type === "Question" && <h4 style={{padding:'12px 20px',borderBottom:`2px solid ${(selectedTab === "Explanation") ? 'black' : 'transparent'}`,color:`${(selectedTab === "Explanation") ? 'black' : 'steelblue'}`,cursor:`${(selectedTab === "Explanation") ? 'default' : 'pointer'}`}} onClick={() => (selectedTab !== "Explanation") && setSelectedTab("Explanation")}>Explanation</h4>}
                    <h4 style={{padding:'12px 20px',borderBottom:`2px solid ${(selectedTab === "Note") ? 'black' : 'transparent'}`,color:`${(selectedTab === "Note") ? 'black' : 'steelblue'}`,cursor:`${(selectedTab === "Note") ? 'default' : 'pointer'}`}} onClick={() => (selectedTab !== "Note") && setSelectedTab("Note")}>Note</h4>
                </div>
            </header>
            {(selectedTab === 'Note') && 
                <header>
                    <div style={{margin:'4px', padding:'4px',cursor:'pointer'}} onClick={ () => resetNote() }>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
                            <g>
                                <path transform="rotate(0,12,12) translate(1.6,1.62112455368042) scale(0.65,0.65)" fill="dodgerblue" d="M15.994003,0C24.819954,-9.1655238E-08 32,7.1629951 32,15.967989 32,24.772006 24.819954,31.935002 15.994003,31.935002 7.3439871,31.935002 0.27899144,25.053988 0,16.492007L4.0790063,15.647005C4.0760156,15.755007 4.062008,15.859987 4.062008,15.967989 4.062008,22.530001 9.4149996,27.871005 15.994003,27.871005 22.573007,27.871005 27.925968,22.530001 27.925968,15.967989 27.925968,9.4039924 22.573007,4.0639956 15.994003,4.0639956 12.93999,4.0639956 10.163992,5.2300117 8.051994,7.1199958L11.938007,10.346009 0.70901443,12.959993 1.2480154,1.4729919 4.8850051,4.4920046C7.7659837,1.7149964,11.680989,-9.1655238E-08,15.994003,0z" />
                            </g>
                        </svg>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{color:'green'}}><strong>2000</strong> characters allowed.</span>
                        {note.length > 2000 ? <span style={{color:'red'}}>Note exceed by {note.length - 2000} characters</span> : <span>{2000 - note.length} characters remaining</span>}
                    </div>
                    <div style={{display:'flex'}}>
                        {(note.trim() === editedNote.trim() || note.length > 2000) ? <div style={{margin:'4px', padding:'4px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
                                <g>
                                    <path transform="rotate(0,12,12) translate(0,0) scale(0.75,0.75)" fill="gray" d="M6.8569946,20.571014L6.8569946,29.713989 25.143005,29.713989 25.143005,20.571014z M9.1430054,2.2860107L9.1430054,9.1430054 20.570984,9.1430054 20.570984,2.2860107 18.286011,2.2860107 18.286011,6.8569946 16,6.8569946 16,2.2860107z M2.2860107,2.2860107L2.2860107,29.713989 4.5709839,29.713989 4.5709839,18.286011 6.8569946,18.286011 25.143005,18.286011 27.429016,18.286011 27.429016,29.713989 29.713989,29.713989 29.713989,4.5720091 27.428022,4.5720091 27.428022,2.2860107 22.856995,2.2860107 22.856995,11.428009 20.570984,11.428009 9.1430054,11.428009 6.8569946,11.428009 6.8569946,2.2860107z M0,0L2.2860107,0 27.429016,0 27.429016,2.2859893 29.71401,2.2859893 29.71401,4.5710144 32,4.5710144 32,29.713989 32,32 0,32 0,29.713989 0,2.2860107z" />
                                </g>
                            </svg>
                        </div> : <div style={{margin:'4px', padding:'4px',cursor:'pointer'}} onClick={() => {saveNote()}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
                                <g>
                                    <path transform="rotate(0,12,12) translate(0,0) scale(0.75,0.75)" fill="dodgerblue" d="M6.8569946,20.571014L6.8569946,29.713989 25.143005,29.713989 25.143005,20.571014z M9.1430054,2.2860107L9.1430054,9.1430054 20.570984,9.1430054 20.570984,2.2860107 18.286011,2.2860107 18.286011,6.8569946 16,6.8569946 16,2.2860107z M2.2860107,2.2860107L2.2860107,29.713989 4.5709839,29.713989 4.5709839,18.286011 6.8569946,18.286011 25.143005,18.286011 27.429016,18.286011 27.429016,29.713989 29.713989,29.713989 29.713989,4.5720091 27.428022,4.5720091 27.428022,2.2860107 22.856995,2.2860107 22.856995,11.428009 20.570984,11.428009 9.1430054,11.428009 6.8569946,11.428009 6.8569946,2.2860107z M0,0L2.2860107,0 27.429016,0 27.429016,2.2859893 29.71401,2.2859893 29.71401,4.5710144 32,4.5710144 32,29.713989 32,32 0,32 0,29.713989 0,2.2860107z" />
                                </g>
                            </svg>
                        </div>}
                        {isNoteEdit ? <div style={{margin:'4px', padding:'4px',cursor:'pointer'}} onClick={() => setIsNoteEdit(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
                                <g>
                                    <path transform="rotate(0,12,12) translate(3,0) scale(0.75,0.75)" fill="dodgerblue" d="M9.9539981,12.993046C9.4189961,12.993046 8.9009943,13.097046 8.4139929,13.303046 6.9479876,13.921046 5.9999843,15.345047 5.9999843,16.931046 5.9999843,19.102046 7.7739906,20.868046 9.9539981,20.868046 12.134006,20.868046 13.908012,19.102046 13.908011,16.931046 13.908012,14.760046 12.134006,12.993046 9.9539981,12.993046z M9.9539981,10.993047C13.237009,10.993047 15.908019,13.657046 15.908019,16.931046 15.908019,18.158796 15.532408,19.300625 14.889767,20.24832L14.820719,20.345066 19.710711,25.294973C20.099709,25.687979 20.095741,26.320975 19.702715,26.708976 19.507698,26.901969 19.253717,26.997977 18.999676,26.997977 18.741667,26.997977 18.483658,26.898978 18.288641,26.70098L13.409086,21.761648 13.280617,21.852805C12.330194,22.493562 11.185127,22.868047 9.9539981,22.868047 6.6709867,22.868047 3.9999771,20.205046 3.9999771,16.931046 3.9999771,14.538046 5.4279823,12.391047 7.63799,11.460046 8.3709927,11.150046 9.1499953,10.993047 9.9539981,10.993047z M6.9999995,3.4140005L3.382988,7.0310125 6.9999995,7.0310125z M8.9999995,2L8.9999995,9.0310116 2,9.0310116 2,30 22,30 22,2z M7.5859995,0L22,0C23.103,0,24,0.89699936,24,2L24,30C24,31.103,23.103,32,22,32L2,32C0.89699936,32,0,31.103,0,30L0,7.5860004z" />
                                </g>
                            </svg>
                        </div> : <div style={{margin:'4px', padding:'4px',cursor:'pointer'}} onClick={() => setIsNoteEdit(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
                                <g>
                                    <path transform="rotate(0,12,12) translate(0,0.000389098750019912) scale(0.749999284744945,0.749999284744945)" fill="dodgerblue" d="M1.867051,22.668933L9.3319602,30.132003 0,31.998993z M18.662943,5.8707967L26.128829,13.335851 11.199011,28.266969 3.7330038,20.800907z M25.196997,0C25.605364,-6.895516E-08,26.013352,0.15525171,26.324872,0.46575543L31.532841,5.6748109C32.155762,6.297802,32.155762,7.3078194,31.532841,7.9318176L27.994782,11.470847 20.53085,4.0047846 24.066832,0.46575543C24.379879,0.15525171,24.788628,-6.895516E-08,25.196997,0z" />
                                </g>
                            </svg>
                        </div>}
                    </div>
                </header>
            }
            <article>
                <main>
                    {selectedTab === "Content" && topic.type === "Video" && <ReactPlayer url={topic.url} height="100%" width="100%" controls={true} />}
                    {selectedTab === "Content" && topic.type !== "Video" && content.trim().length > 0 && <div style={{padding:'12px 12px 12px 24px'}} dangerouslySetInnerHTML={{__html: marked(content, { renderer: renderer })}} className="markdown" />}
                    {selectedTab === "Explanation" && topic.dtype === "Video" && <ReactPlayer url={topic.durl} height="100%" width="100%" controls={true} />}
                    {selectedTab === "Explanation" && topic.dtype === "Text" && explanation.trim().length > 0 && <div style={{padding:'12px 12px 12px 24px'}} dangerouslySetInnerHTML={{__html: marked(explanation, { renderer: renderer })}} className="markdown" />}
                    {selectedTab === "Note" && isNoteEdit && <textarea style={{width:'100%',height:'100%',padding:'12px',border:'none'}} value={note} onChange={e => setNote(e.target.value)} type="text" />}
                    {selectedTab === "Note" && !isNoteEdit && <div style={{padding:'12px 12px 12px 24px'}} dangerouslySetInnerHTML={{__html: marked(note, { renderer: renderer })}} className="markdown" />}
                </main>
            </article>
            {(topic.type === "Question") && (selectedTab === "Content") &&
                <header style={{display:'flex',justifyContent:'flex-start',alignItems:'center',backgroundColor:'#f5f5f5'}}>
                    <h3 style={{color:'steelblue', margin:'0 18px'}}>Answer</h3>
                    {topic.answer.map(a => {
                        return <div key={a} style={{margin:'8px 4px',padding:'2px 12px',fontSize:'x-large',color:'#ac8e6a',backgroundColor:'#202c38',borderRadius:'6px'}}>{a}</div>
                    })}
                </header>
            }
        </div>
    );
}

export default Content;