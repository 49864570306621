import React from "react";

export default function Block ({color}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
            <g>
                <path transform="rotate(0,16,16) translate(1.6,1.6) scale(0.9,0.9)" fill={color} d="M5.5009975,15.000014L26.500998,15.000014 26.500998,17.000015 5.5009975,17.000015z M16,2C8.2799988,2 2,8.2799072 2,16 2,23.719971 8.2799988,30 16,30 23.720001,30 30,23.719971 30,16 30,8.2799072 23.720001,2 16,2z M16,0C24.821999,0 32,7.1779785 32,16 32,24.821899 24.821999,32 16,32 7.1780014,32 0,24.821899 0,16 0,7.1779785 7.1780014,0 16,0z" />
            </g>
        </svg>
    );
}