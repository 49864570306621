import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import auth from "../login/auth/auth";
import wallet from "./wallet";

let API_URL = process.env.REACT_APP_API_URL;

const AmountBalance = () => {
    const [amountBalance, setAmountBalance] = useState(0);
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
        };
        fetch(`${API_URL}/amount_balance?token=${auth.token}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setAmountBalance(result);
            wallet.update(result);
        })
        .catch(error => error);
    }, []);
    return (
        <Link to={'/payments'} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end',padding:'6px 9px 6px 6px',cursor:'pointer',color:'gainsboro'}}>
            <h5>Amount Balance</h5>
            <h4 style={{display:'flex'}}>₹&nbsp;
                {amountBalance}
            </h4>
        </Link>
    );
}

export default AmountBalance;