import {useState} from 'react';
import countries from "../../countries.json";
import courses from "../../courses/courses.json";

const CountrySelect = (props) => {
	function changeCountry (e) {
		props.changeCountry(e.target.value);
	}
	const cs = countries.filter(country => {
        if (courses[country.iso] && country.iso.length === 2) {
            return true;
        }
        return false;
     });
	return (
		<div style={{display:'flex',alignItems:'center',borderBottom:'2px solid slategray'}}>
		    <img src={props.country.flag} alt="" style={{width: '16px', height: '16px'}}/>
            <select style={{border:'none',padding:'5px',outline:0,backgroundColor:'transparent',width:'100%'}} value={props.country.iso} onChange={e => changeCountry(e)}>
                {cs.map(country => {
			    return (<option value={country.iso} key={country.iso}>
					{country.name}
					</option>);
				})}
			</select>
		</div>
    )
}

const PhoneNumber = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
	function changeCountry (country) {
        setPhoneNumber('');
		props.changeCountry(country);
	}
    function changePhoneNumber (e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhoneNumber(e.target.value);
        }
    }
    return (
        <div>
            <CountrySelect country={props.country} changeCountry={changeCountry} />
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'2px solid slategray',marginTop:'12px'}}>
                <span style={{marginBottom:'2px',fontWeight:'bolder'}}>{props.country.isd}&nbsp;-&nbsp;</span>
                <input type="tel" style={{border:'none',backgroundColor:'transparent',outline:'none'}} placeholder='phone number' value={phoneNumber} onChange={changePhoneNumber} />
                {(props.country.phoneLength !== phoneNumber.length) ? (
                    <button style={{backgroundColor:"#e0ebf0", color: "gray", marginTop:'4px',padding:'8px',border:'none'}}>
                          Request&nbsp;OTP
                    </button>) : (<button style={{backgroundColor: "rosybrown", color: "whitesmoke", marginTop:'4px',padding:'8px',border:'none',cursor:'pointer'}} onClick={() => props.changePhoneNumber(phoneNumber)}>
                        Request&nbsp;OTP
                    </button>)
                }     
            </div>
        </div>
    );    
}

export default PhoneNumber;