import { useState } from "react";
import { useLocation } from 'react-router-dom'
import auth from "./auth/auth";
import PhoneNumber from './phonenumber';
import OTP from './otp';
import countries from "../../countries.json";

const Login = () => {
    auth.logout();
    const location = useLocation();
    const countryInit = countries.find(country => country.iso === "IN");
    const [country, setCountry] = useState(countryInit);
    const [phoneNumber, setPhoneNumber] = useState('');        
    const fromURL = location.state?.fromURL ?? "/";
    function changeCountry(countryISO) {
        const c = countries.find(country => country.iso === countryISO)
        setCountry(c);
    }
    function changePhoneNumber (phoneNumber) {
        setPhoneNumber(phoneNumber);
    }
    function resetPhoneNumber () {
        setPhoneNumber('');
    }
    return (
        <div className="content" style={{backgroundColor:'#e2e9ee'}}>
            <header>
                <a style={{display:'flex',alignItems:'center',margin:'6px 12px 6px 24px',color:'lightblue',fontFamily:"'Modak',cursive",fontSize:'60px'}} href="https://suryaneel.com">
                    ई गुरुजी
                </a>
                <div>&nbsp;</div>
            </header>
            <article>
                <main>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'4px solid #202c38',marginBottom:'9px'}}>
                                <h3 style={{display:'flex',backgroundColor:'#202c38',padding:'6px 12px 6px 12px',color:'gainsboro'}}>लॉग इन करें</h3>
                                <div>&nbsp;</div>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',padding:'24px 12px 24px 12px',backgroundColor:'ghostwhite'}}>
                                {(!phoneNumber) ? 
                                    <PhoneNumber country={country} changeCountry={changeCountry} changePhoneNumber={changePhoneNumber} /> : 
                                    <OTP country={country} phoneNumber={phoneNumber} resetPhoneNumber={resetPhoneNumber} fromURL={fromURL} />
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </article>
        </div>
    );
}

export default Login;