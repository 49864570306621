import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import auth from "../login/auth/auth";
import AmountBalance from "../wallet/AmountBalance";
import wallet from "../wallet/wallet";
import Question from "./Question";

let API_URL = process.env.REACT_APP_API_URL;

const Test = () => {
    let { topicid } = useParams();
    const navigate = useNavigate();
    const [topic, setTopic] = useState({});
    const [questionIndex, setQuestionIndex] = useState(0);

    const backURL = (() => {
        const tokens = topicid.split(".");
        if (tokens.length === 1) {
            return '/';
        } else if (tokens.length > 1) {
            return '/topics/' + topicid;
        }
    })();

    useEffect(() => {
        if (auth.token === '') {
            navigate(backURL);
        }
        if (wallet.amountBalance < 1) {
            navigate(backURL);
        }
        let t = {};
        fetch(`${API_URL}/test?id=${topicid}&token=${auth.token}`, { method: 'GET'})
            .then(response => response.json())
            .then(result => {
                setTopic({...t, ...result, QCs: Number.parseFloat(result.QCs).toFixed(2) });
                setQuestionIndex(0);
            })
            .catch(() => setTopic(t));
    }, [])

    function logOut () {
        auth.logout();
        navigate(backURL);
    }

    return (
        <div className="content">
            <header style={{backgroundColor:'#2d2d37',color:'#9c9da7'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <Link style={{display:'flex',justifyContent:'center',width:'48px',height:'48px',alignItems:'center',alignSelf:'center',cursor:'pointer'}} to={backURL}>
                        <span style={{display:'flex',justifyContent:'center',fontFamily:'Arial, sans-serif',border:'2px solid #ac8e6a',borderRadius:'50% 50% 50% 50%',width:'32px',height:'32px',color:'#ac8e6a',fontSize:'24px',fontWeight:'bolder'}}>↑</span>
                    </Link>
                    <div style={{display:'flex',flexDirection:'column',marginLeft:'6px'}}>
                        <h4>TEST: {topic.title}</h4>
                    </div>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                        <AmountBalance color="gainsboro" />
                        <div style={{display:'flex',cursor: 'pointer',alignSelf:'stretch',alignItems: 'center',padding: '0 8px 0 8px',borderLeft:'1px solid #30435a'}} onClick={() => logOut()}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
                                <g>
                                    <path transform="rotate(0,16,16) translate(0,3.26239487854619) scale(0.999969006545341,0.999969006545341)" fill="gainsboro" d="M23.172997,19.730999L32.000992,19.730999 32.000992,21.729014 23.172997,21.729014z M15.939967,13.816008L21.176005,13.816008 21.176005,14.815015 15.939967,14.815015z M8.9989913,12.807005L9.710994,12.807005C11.460001,12.807005,12.883008,14.229006,12.883008,15.978007L12.883008,18.281006 11.884004,18.281006 11.884004,15.978007C11.884004,14.780006,10.909,13.806006,9.710994,13.806006L8.9989913,13.806006C7.8009856,13.806006,6.8259814,14.780006,6.8259814,15.978007L6.8259814,18.281006 5.826977,18.281006 5.826977,15.978007C5.826977,14.229006,7.2499831,12.807005,8.9989913,12.807005z M15.939005,9.8190023L26.171009,9.8190023 26.171009,10.81801 15.939005,10.81801z M9.3449958,8.0330116C8.4529946,8.0330116 7.7279942,8.758012 7.7279942,9.6500112 7.7279942,10.541011 8.4529946,11.26601 9.3449958,11.26601 10.236997,11.26601 10.961997,10.541011 10.961997,9.6500112 10.961997,8.758012 10.236997,8.0330116 9.3449958,8.0330116z M9.3449958,7.0340125C10.786997,7.0340125 11.960999,8.2080118 11.960999,9.6500112 11.960999,11.092011 10.786997,12.26501 9.3449958,12.26501 7.9029944,12.26501 6.7289932,11.092011 6.7289932,9.6500112 6.7289932,8.2080118 7.9029944,7.0340125 9.3449958,7.0340125z M0,0L31.971001,0 31.971001,12.389001 29.973011,12.389001 29.973011,1.9979996 1.9989658,1.9979996 1.9989658,23.477984 18.983002,23.477984 18.983002,25.476 0,25.476z" />
                                </g>
                            </svg>
                            <h4 style={{marginLeft:'6px',color:'gainsboro'}}>log&nbsp;out</h4>
                        </div>
                    </div>
            </header>
            <header style={{backgroundColor:'#383943', color: '#9c9da7', fontSize: 'smaller'}}>
                <div style={{display:'flex',flexWrap:'wrap',margin:'6px 6px 6px 12px',alignItems:'center'}}>
                    <h4 style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>QUESTIONS:&nbsp;{questionIndex + 1} of {topic.questions && topic.questions.length}</h4>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Visits:&nbsp;<strong>{topic.QVs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Visit Times:&nbsp;<strong>{topic.QVTs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Marked:&nbsp;<strong>{topic.QMs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Blocked:&nbsp;<strong>{topic.QBs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Answered:&nbsp;<strong>{topic.QAs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap',marginRight:'12px'}}>Answered Times:&nbsp;<strong>{topic.QATs}</strong></span>
                    <span style={{display:'flex',flexWrap:'no-wrap'}}>Cleared:&nbsp;<strong>{topic.QCs}</strong></span>
                </div>
                {topic.questions && (questionIndex < topic.questions.length) 
                    ? <div style={{display:'flex',justifyContent:'center',cursor:'pointer',padding:'6px 9px 6px 6px',backgroundColor:'steelblue',color:'whitesmoke',borderRadius:'4px',margin:'12px'}} onClick={() => setQuestionIndex(questionIndex + 1)}>
                        <h4>Next Question &gt;&gt;</h4>
                    </div> 
                    : <div style={{display:'flex',justifyContent:'center',padding:'6px 9px 6px 6px',backgroundColor:'silver',color:'slategray',borderRadius:'4px',margin:'12px'}}>
                        <h4>Next Question &gt;&gt;</h4>
                    </div>
                }
            </header>
            {topic.questions && (topic.questions.length > 0) &&
                <Question id={topic.questions[questionIndex]} />
            }
        </div>
    );
}

export default Test;