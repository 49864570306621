class Auth {
    constructor() {
        this.country = undefined;
        this.phone = '';
        this.token = '';
    }
    login(country, phone, token) {
        this.country = {...country};
        this.phone = phone;
        this.token = token;
    }
    logout() {
        this.country = undefined;
        this.phone = '';
        this.token = '';
    }
}

export default new Auth();